import React, { Fragment } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { Props, Image, ChildMarkdownRemark } from '../../types/basicTypes';
import { Layout } from '../../components';
import { MEDIA_QUERY, COLORS } from '../../commonStyles';

const aboutServiceData = (language: string) => {
  const { allContentfulServiceDescription } = useStaticQuery(graphql`
    query AboutServiceQuery {
      allContentfulServiceDescription {
        nodes {
          node_locale
          image {
            file {
              url
            }
          }
          imageMobile {
            file {
              url
            }
          }
          slogan
          info
          imageSlogan
          description {
            description
            childMarkdownRemark {
              html
            }
          }
          elements {
            node_locale
            title
            isTable
            description {
              description
              childMarkdownRemark {
                html
              }
            }
            elements {
              description {
                description
                childMarkdownRemark {
                  html
                }
              }
              title
              isTable
              node_locale
            }
          }
        }
      }
    }
  `);
  return allContentfulServiceDescription.nodes.find(
    ({ node_locale: nodeLocale }: { node_locale: string }) =>
      nodeLocale === language
  );
};

type Description = {
  description: string;
  childMarkdownRemark: ChildMarkdownRemark;
};

type Element = {
  title: string;
  description: Description;
  elements: Element[];
  isTable: boolean;
};

type Data = {
  image: Image;
  imageMobile: Image;
  slogan: string;
  info: string;
  imageSlogan: string;
  description: Description;
  elements: Element[];
};

type Row = {
  product: string;
  for2: string;
  for4: string;
  for6: string;
  for8: string;
  for10: string;
  for12: string;
};

const Root = styled.div({
  margin: 'auto',
});

const TableContainer = styled.div({
  display: 'grid',
  gridTemplateColumns: '2fr repeat(6, 1fr)',
  gridColumnGap: '0px',
  gridRowGap: '0px',
  width: '100%',
  fontFamily: 'Arial',
  fontSize: '16px',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '1.25',
  marginTop: '10px',
  marginBottom: '50px',
  [MEDIA_QUERY.TABLET]: {},
  [MEDIA_QUERY.MOBILE]: {
    lineHeight: '1',
    letterSpacing: '0.2px',
    fontSize: '12px',
  },
});

const HeaderCell = styled.div<Record<string, any>>(props => ({
  gridColumn: props.column,
  gridRow: props.row,
  fontFamily: 'Arial',
  fontSize: '16px',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '1.25',
  letterSpacing: 'normal',
  color: '#000000',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  paddingRight: '10px',
  whiteSpace: 'nowrap',
  [MEDIA_QUERY.MOBILE]: {
    lineHeight: '16px',
    fontSize: '11px',
    marginTop: '15px',
    marginBottom: '6px',
    paddingRight: '5px',
  },
}));

const FirstCell = styled.div<Record<string, any>>(props => ({
  gridColumn: props.column,
  gridRow: props.row,
  background: props.background,
  padding: '12px',
  fontFamily: 'Arial',
  fontSize: '16px',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '1.25',
  letterSpacing: 'normal',
  color: '#000000',
  textAlign: 'left',
  width: '180px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  [MEDIA_QUERY.MOBILE]: {
    lineHeight: '16px',
    fontSize: '11px',
    width: '62px',
  },
}));

const ValueCell = styled.div<Record<string, any>>(props => ({
  gridColumn: props.column,
  gridRow: props.row,
  background: props.background,
  fontFamily: 'Arial',
  fontSize: '16px',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '1.25',
  letterSpacing: 'normal',
  color: '#000000',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  paddingRight: '10px',
  [MEDIA_QUERY.MOBILE]: {
    lineHeight: '16px',
    fontSize: '11px',
    paddingRight: '5px',
  },
}));

const renderHeader = (row: Row, index: number) => {
  return (
    <Fragment key={`__header_${index}`}>
      <HeaderCell column={2} row={1}>
        <p>{row.for2}</p>
      </HeaderCell>
      <HeaderCell column={3} row={1}>
        <p>{row.for4}</p>
      </HeaderCell>
      <HeaderCell column={4} row={1}>
        <p>{row.for6}</p>
      </HeaderCell>
      <HeaderCell column={5} row={1}>
        <p>{row.for8}</p>
      </HeaderCell>
      <HeaderCell column={6} row={1}>
        <p>{row.for10}</p>
      </HeaderCell>
      <HeaderCell column={7} row={1}>
        <p>{row.for12}</p>
      </HeaderCell>
    </Fragment>
  );
};

const renderRow = (row: Row, index: number) => {
  const background = index % 2 == 0 ? '#f7efda' : '';
  return (
    <Fragment key={`__row_${index}`}>
      <FirstCell
        key={`__serd_$1{index}`}
        column={1}
        row={index}
        background={background}
      >
        <p>{row.product}</p>
      </FirstCell>
      <ValueCell
        key={`__serd_$2{index}`}
        column={2}
        row={index}
        background={background}
      >
        <p>{row.for2}</p>
      </ValueCell>
      <ValueCell
        key={`__serd_$3{index}`}
        column={3}
        row={index}
        background={background}
      >
        <p>{row.for4}</p>
      </ValueCell>
      <ValueCell
        key={`__serd_$4{index}`}
        column={4}
        row={index}
        background={background}
      >
        <p>{row.for6}</p>
      </ValueCell>
      <ValueCell
        key={`__serd_$5{index}`}
        column={5}
        row={index}
        background={background}
      >
        <p>{row.for8}</p>
      </ValueCell>
      <ValueCell
        key={`__serd_$6{index}`}
        column={6}
        row={index}
        background={background}
      >
        <p>{row.for10}</p>
      </ValueCell>
      <ValueCell
        key={`__serd_$7{index}`}
        column={7}
        row={index}
        background={background}
      >
        <p>{row.for12}</p>
      </ValueCell>
    </Fragment>
  );
};

const renderTable = (json: string) => {
  const rows: Row[] = JSON.parse(json);
  const phrase: JSX.Element[] = [];
  rows.forEach((row, index) => {
    if (index == 0) {
      phrase.push(renderHeader(row, index + 1));
    } else {
      phrase.push(renderRow(row, index + 1));
    }
  });
  return phrase;
};

const renderSubElement = (element: Element, idx: number) => {
  return (
    <Fragment key={`__serd_${idx}`}>
      <ElementSubTitle key={`__et_${idx}`}>{element.title}</ElementSubTitle>
      {element.isTable ? (
        <TableContainer key={`__etbl_${idx}`}>
          {renderTable(element.description.description)}
        </TableContainer>
      ) : (
        <ElementDescription
          key={`__ed_${idx}`}
          dangerouslySetInnerHTML={{
            __html: element.description.childMarkdownRemark.html,
          }}
        />
      )}
    </Fragment>
  );
};

const renderElement = (element: Element, index: number) => {
  return (
    <Fragment key={`__erd_${index}`}>
      <ElementTitle key={`__et_${index}`}>{element.title}</ElementTitle>
      <ElementDescription
        key={`__ed_${index}`}
        dangerouslySetInnerHTML={{
          __html: element.description.childMarkdownRemark.html,
        }}
      />
      <>
        {element.elements != null
          ? element.elements.map((e, idx) =>
              renderSubElement(e, index * 10 + idx)
            )
          : ''}
      </>
    </Fragment>
  );
};

const AboutService: React.FC<Props> = ({ pageContext: { language } }) => {
  const {
    image,
    imageMobile,
    slogan,
    info,
    imageSlogan,
    description,
    elements,
  }: Data = aboutServiceData(language);

  return (
    <Layout title={imageSlogan} language={language}>
      <Root>
        <Container>
          <ImageContainer>
            <ImageSlogan>{imageSlogan}</ImageSlogan>
            <MainImage
              src={image.file.url + '?w=1248&h=624'}
              alt="{imageSlogan}"
            />
            <MainMobileImage
              src={imageMobile.file.url + '?w=688&h=688'}
              alt="{imageSlogan}"
            />
          </ImageContainer>
          <ContentContainer>
            <Info>{info}</Info>
            <Slogan>{slogan}</Slogan>
            <DescriptionContainer
              dangerouslySetInnerHTML={{
                __html: description.childMarkdownRemark.html,
              }}
            />
            {elements.map((element, index) =>
              renderElement(element, index + 1)
            )}
          </ContentContainer>
        </Container>
      </Root>
    </Layout>
  );
};

const Container = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  [MEDIA_QUERY.TABLET]: {},
  [MEDIA_QUERY.MOBILE]: {},
});

const ImageContainer = styled.div({
  display: 'flex',
  maxWidth: '1248px',
  position: 'relative',
  [MEDIA_QUERY.TABLET]: {
    maxWidth: '922px',
  },
  [MEDIA_QUERY.MOBILE]: {
    padding: '20px',
  },
});

const ContentContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  maxWidth: '608px',
  [MEDIA_QUERY.MOBILE]: {
    paddingLeft: '20px',
    paddingRight: '20px',
    alignItems: 'center',
  },
  [MEDIA_QUERY.TABLET]: {
    maxWidth: '508px',
  },
});

const ImageSlogan = styled.h3({
  fontFamily: 'Arial',
  fontSize: '72px',
  fontWeight: 'bold',
  lineHeight: '1',
  margin: '0',
  color: COLORS.DARK_GREY,
  display: 'inline',
  position: 'absolute',
  top: '20px',
  left: '20px',
  right: '20px',
  fontStretch: 'normal',
  fontStyle: 'normal',
  letterSpacing: 'normal',
  textAlign: 'left',
  paddingTop: '57px',
  maxWidth: '540px',
  [MEDIA_QUERY.MOBILE]: {
    fontSize: '36px',
    paddingTop: '40px',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  [MEDIA_QUERY.TABLET]: {
    paddingLeft: '32px',
    top: 0,
    left: 0,
    right: 'auto',
  },
  [MEDIA_QUERY.DESKTOP]: {
    paddingLeft: '48px',
    top: 0,
    left: 0,
    right: 'auto',
  },
});

const MainImage = styled.img({
  width: '100%',
  height: '100%',
  [MEDIA_QUERY.MOBILE]: {
    display: 'none',
  },
  [MEDIA_QUERY.TABLET]: {
    display: 'none',
  },
  [MEDIA_QUERY.DESKTOP]: {
    maxHeight: '624px',
  },
});

const MainMobileImage = styled.img({
  width: '100%',
  height: '100%',
  [MEDIA_QUERY.MOBILE]: {},
  [MEDIA_QUERY.TABLET]: {
    maxHeight: '688px',
  },
  [MEDIA_QUERY.DESKTOP]: {
    display: 'none',
  },
});

const Info = styled.div({
  fontFamily: 'Arial',
  fontSize: '24px',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '1.42',
  letterSpacing: 'normal',
  color: COLORS.DARK_GREY,
  textAlign: 'left',
  marginTop: '61px',
  [MEDIA_QUERY.TABLET]: {
    marginTop: '54px',
  },
  [MEDIA_QUERY.MOBILE]: {
    lineHeight: '1.24',
    fontSize: '21px',
    margin: '15px',
  },
});

const Slogan = styled.div({
  backgroundColor: '#f7efda',
  fontFamily: 'Arial',
  fontSize: '36px',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '1.23',
  letterSpacing: 'normal',
  color: COLORS.BLACK,
  marginTop: '40px',
  marginBottom: '40px',
  textAlign: 'left',
  padding: '20px',
  [MEDIA_QUERY.TABLET]: {
    lineHeight: '1.22',
    fontSize: '36px',
  },
  [MEDIA_QUERY.MOBILE]: {
    lineHeight: '1.33',
    fontSize: '24px',
    marginTop: '20px',
    marginBottom: '20px',
  },
});

const DescriptionContainer = styled.div({
  p: {
    a: {
      color: '#232425',
      textDecoration: 'underline',
      targetNew: 'tab',
    },
  },
  fontFamily: 'Arial',
  fontSize: '18px',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '1.56',
  letterSpacing: 'normal',
  color: COLORS.DARK_GREY,
  textAlign: 'left',
  marginBottom: '20px',
  [MEDIA_QUERY.MOBILE]: {
    lineHeight: '1.5',
    fontSize: '16px',
  },
});

const ElementTitle = styled.div({
  fontFamily: 'Arial',
  fontSize: '24px',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '1.33',
  letterSpacing: 'normal',
  color: 'black',
  marginTop: '0px',
  textAlign: 'left',
  [MEDIA_QUERY.MOBILE]: {
    lineHeight: '1.5',
    fontSize: '16px',
    alignSelf: 'flex-start',
  },
});

const ElementDescription = styled.div({
  p: {
    a: {
      color: '#232425',
      textDecoration: 'underline',
    },
  },
  fontFamily: 'Arial',
  fontSize: '18px',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '1.56',
  letterSpacing: 'normal',
  color: COLORS.DARK_GREY,
  textAlign: 'left',
  marginTop: '20px',
  marginBottom: '40px',
  [MEDIA_QUERY.MOBILE]: {
    lineHeight: '1.5',
    fontSize: '16px',
    marginBottom: '20px',
  },
});

const ElementSubTitle = styled.div({
  fontFamily: 'Arial',
  fontSize: '18px',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '1.56',
  letterSpacing: 'normal',
  color: 'black',
  marginTop: '0px',
  textAlign: 'left',
  [MEDIA_QUERY.MOBILE]: {
    lineHeight: '1.5',
    fontSize: '16px',
    alignSelf: 'flex-start',
  },
});

export default AboutService;
